#photo-page {
    max-width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#photo-page h1 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 400;
}

.photos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: min-content;
    align-items: flex-start;
    align-content: flex-start;
}

/*  */
.photo {
    width: 30%;
    height: min-content;
    margin: .3em;
    cursor: pointer;
}

.photo:hover {
    width: 31%;
    transition: all .30s;
}

.photo img {
    width: 100%;
    opacity: .92;
}

.photo img:hover {
    opacity: 1.0;
}

/* Modal */
.modal-content {
    width: 80%;
    /* height: 500px; */
    margin: auto;
    background-color: black;

    /* Default */
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    outline: none;
    padding: 10px;
    border-radius: 2px;
}

.modal-content img {
    width: 100%;
    /* max-height: 80vh; */
}

.modal-content svg {
    cursor: pointer;
    float: right;
    margin-bottom: 5px;
}

.modal-overlay {
    background-color: rgb(0, 0, 0, .8);

    /* Default */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media screen and (max-width: 1330px) {
    #photo-page {
        max-width: 80%;
    }

    .modal-content {
        width: 80%;
        height: fit-content;
    }
}

@media screen and (max-width: 630px) {
    #photo-page h1 {
        font-size: 24px;
    }

    .photo {
        width: 30%;
        margin: .2em;
    }
}

@media screen and (max-width: 470px) {
    #photo-page h1 {
        font-size: 18px;
    }
}

@media screen and (max-width: 430px) {
    .photo {
        width: 47%;
    }

    .photo:hover {
        width: 48%;
    }

    .modal-content {
        left: 10%;
        right: 10%;
    }
}

@media screen and (max-width: 355px) {
    #photo-page h1 {
        font-size: 16px;
    }
}

/* Até 310 tem que estar bom  */