#navbar{
    background-color: rgba(209, 203, 203, 0.815);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgb(229, 231, 235);
}
#titulo{
    width: 100%;
    height: 10vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#titulo p{
    text-align: center;
}
#nav-links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 100%;
    /* position: sticky;
    top: 0;
    background-color: red; */
}
#nav-links li{
    margin-left: 15px;
}
#nav-links svg{
    font-size: .6em;
    margin-right: 10px;
}
#nav-links li:hover{
    font-weight: bold;
    transition: all .30s;
}
@media screen and (max-width: 430px){
    #titulo{
        height: 8vh;
    }
    #navbar h2{
        font-size: 16px;
    }
    #navbar p{
        font-size: 12px;
    }
    #nav-links{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: auto;
        height: fit-content; 
    }
    #nav-links li{
        margin-top: 4px;
    }
}