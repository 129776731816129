body {
    font-family: 'STIX Two Math', serif, sans-serif;
}

#home-page {
    max-width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home-page h1 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 400;
}

.image-caboFrio {
    width: 45%;
    padding: 1%;
}

.image-caboFrio img {
    width: 100%;
}

.bg-blue:hover {
    background-color: rgba(53, 140, 221, 0.397);
    border-color: gold;
    transition: all .30s;
}

.reserve {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 314px;
    justify-content: center;
    align-items: center;
    padding: 1%;
    text-align: justify;
    order: 0;
    background-color: rgba(53, 140, 221, 0.397);
}

.reserve h2 {
    text-align: center;
    font-weight: 500;
    font-size: 32px;
    text-decoration: underline;
}

.reserve svg {
    font-size: 1.2em;
}

.reserve p {
    font-size: 24px;
}

@media screen and (max-width: 560px) {
    .reserve h2 {
        font-size: 24px;
    }

    .reserve svg {
        font-size: 1.0em;
    }

    .reserve p {
        font-size: 16px;
    }
}