#ratings-page{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#ratings-page h1 {
    margin-top: 30px;
    font-weight: 400;
    margin-bottom: 5px;
}
#ratings-page p{
    margin-bottom: 15px;
}
.overall-ratings{
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid black;
    margin-bottom: 30px;
}
.details{
    display: flex;
    flex-direction: column;
    width: 55%;
    height: fit-content;
    justify-content: center; 
    padding: 10%;
    text-align: justify;
    order: 0;
}
.details h2{
    text-align: center;
    font-weight: 400;
}
.details a{
    text-decoration: underline;
}
.image{
    padding: 8%;
    width: 45%;
}
.image img{
    width: 100%;
}
.avaliacoes{
    padding: 3%;
}
.order{
    order: -1;
}
@media screen and (max-width: 830px) {
    .image{
        padding: 3%;
    }
}
@media screen and (max-width: 630px) {
    .overall-ratings{
        flex-direction: column;
    }
    .details{
        width: 100%;
        order: -1;
    }
    .image{
        width: 80%;
    }
}
@media screen and (max-width: 470px) {
    #ratings-page p {
        font-size: 12px;
    }
}
@media screen and (max-width: 420px) {
    #ratings-page h1 {
        font-size: 24px;
    }
}