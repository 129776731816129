*{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: black;
}
/* .container{
    min-height: 70vh;
} */
a{
    text-decoration: none;
}
ul{
    list-style: none;
}