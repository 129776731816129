#localization-page{
    max-width: 70%;
    /* background-color: gray; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#localization-page h1 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 400;
}
.google iframe{
    margin-bottom: 10px;
}
.localDetails{
    /* border: 1px solid black; */
    margin-bottom: 10px;
}

@media screen and (max-width: 510px) {
    #localization-page h1 {
        font-size: 24px;
    }
}
@media screen and (max-width: 430px) {
    .google iframe{
        height: 60vh;
    }
}
@media screen and (max-width: 380px) {
    #localization-page h1 {
        font-size: 18px;
    }
}